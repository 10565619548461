import "./Footer2.css";

function Footer2() {
  return (
    <div className="footer2">
      <b>
        <p>
          Copyright DevZone Innovations - 2024. Todos os direitos reservados.
        </p>
      </b>
    </div>
  );
}

export default Footer2;
